export const bankAccounts = [
    {
        image: 'https://zooblin-public.s3.eu-west-2.amazonaws.com/images/agib-xs.png',
        bankCode: 'AGIB',
        bankName: 'Arab Gambia Islamic Bank',
        name: 'Taybull Soft',
        accountNumber: '202 0101 11071 109',
        bban: '003 202 0101 11071 109',
    },
    {
        image: 'https://zooblin-public.s3.eu-west-2.amazonaws.com/images/bloom-bank-xs.jpeg',
        bankCode: 'BLOOMBANK',
        bankName: 'Bloom Bank',
        name: 'Taybull Soft',
        accountNumber: '101177019964377',
        bban: '013201177019964377',
    },
    {
        image: 'https://zooblin-public.s3.eu-west-2.amazonaws.com/images/ecobank-xs.jpeg',
        bankCode: 'ECOBANK',
        bankName: 'Ecobank',
        name: 'Taybull Soft',
        accountNumber: '6240032336',
        bban: '',
    },
    {
        image: 'https://taybullpay-public.s3.eu-west-2.amazonaws.com/logo/gtbank-logo.png',
        bankCode: 'GTBANK',
        bankName: 'Guaranty Trust Bank',
        name: 'Taybull Soft',
        accountNumber: '207410561110',
        bban: '',
    },
    {
        image: 'https://zooblin-public.s3.eu-west-2.amazonaws.com/images/trust-bank-xs.jpg',
        bankCode: 'TRUSTBANK',
        bankName: 'Trust Bank',
        name: 'Taybull Soft',
        accountNumber: '11070189701',
        bban: '',
    },
]