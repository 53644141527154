<template>
  <div class="pa-2">
    <h1 class="subtitle-2 pb-5">
      Please make a manual bank transfer to the account details below with your
      name as reference number
    </h1>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="font-weight-black"
          >Bank Account Name</v-list-item-title
        >
        <v-list-item-subtitle>TaybullPay LTD</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="font-weight-black">IBAN</v-list-item-title>
        <v-list-item-subtitle>BE18967722000365</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="font-weight-black"
          >Sort Code</v-list-item-title
        >
        <v-list-item-subtitle>04-07-97</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item> -->
    <div>
      <v-alert outlined type="warning" prominent border="left">
        Please note failure to transfer the full amount within 24 hours will
        result in delays processing your transfer or cancellation
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({ drawer: null }),
  methods: {
    emitNext() {
      this.$emit("next");
    },
  },
};
</script>
