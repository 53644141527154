import { parsePhoneNumber } from "libphonenumber-js";

const numberStarts = {
    "3": {
        name: "QCell",
        logo: 'qcell.jpeg'
    },
    "5": {
        name: "QCell",
        logo: 'qcell.jpeg'
    },
    "7": {
        name: "Africell",
        logo: 'africell.png'
    },
    "2": {
        name: "Africell",
        logo: 'africell.png'
    },
    "4": {
        name: "Africell",
        logo: 'africell.png'
    },
    "9": {
        name: "Gamcell",
        logo: 'gamcel.png'
    },
    "6": {
        name: "Comium",
        logo: 'comium.jpeg'
    },
};
const getOperator = (result) => {
    const n = result.number.replace("+" + result.countryCallingCode, "");
    return numberStarts[n.charAt(0)] ? numberStarts[n.charAt(0)] : null;
}

const normaliseAirtimeNumber = (number) => {

    const n = getTemplate('GM', number);
    
    const result = {
        input: number,
        number: null,
        valid: false,
        countryCallingCode: null,
        operator: null,
    };
    if (n && n.isValid()) {
        result.valid = true;
        result.number = n.number;
        result.countryCallingCode = n.countryCallingCode;
        result.operator = getOperator(result);
    }
    // console.log('result', result);
    return result;
    // if (
    //     this.phoneNumber.startsWith("3") ||
    //     this.phoneNumber.startsWith("5")
    //   ) {
    //     this.operator = "Qcell";
    //   } else if (
    //     this.phoneNumber.startsWith("7") ||
    //     this.phoneNumber.startsWith("2")
    //   ) {
    //     this.operator = "Africell";
    //   } else if (this.phoneNumber.startsWith("9")) {
    //     this.operator = "Gamcell";
    //   } else {
    //     this.operator = "Operator not supported";
    //   }

}


const getTemplate = (state, text) => {
    try {
        const phoneNumber = state
            ? parsePhoneNumber(text, state)
            : parsePhoneNumber(text);
        if (phoneNumber && phoneNumber.isValid()) {
            return phoneNumber;
        }
    } catch (error) {
        // console.log("error", error);
    }
    return null;
};
const getCountryState = (states, text) => {
    let template;
    for (let i = 0; i < states.length; i++) {
        template = getTemplate(states[i], text);
        if (template) {
            break;
        }
    }
    return template;
};
const validatePhoneNumber = (number) => {
    const mobileNumber = number;
    let validPhoneNumber = null;
    if (mobileNumber && mobileNumber.trim()) {
        const phoneNumber = getCountryState(
            ["GM", "GB", "US", null],
            mobileNumber
        );
        if (phoneNumber && phoneNumber.isValid()) {
            validPhoneNumber = phoneNumber.number;
        } else {
            validPhoneNumber = null;
        }
    }
    //  console.log('valid', validPhoneNumber)
    return validPhoneNumber;
};
const getAirtimeNumberType = (number) => {
    // console.log('num1', number)
    // if(number.substring(0, 4) === '+220'){
    //   number = number.substring(4);
    // }
    // console.log('num2', number)
    if (
        (number.substring(4))[0] === "7" ||
        (number.substring(4))[0] === "2" ||
        (number.substring(4))[0] === "4" 
        
    ) {
        return "Africell";
    } else if (
        (number.substring(4))[0] === "3" ||
        (number.substring(4))[0] === "5"
    ) {
        return "Qcell";
    } else if ((number.substring(4))[0] === "9") {
        return "Gamcell";
    } else if ((number.substring(4))[0] === "6") {
        return "Comium";
    }
};
const serviceFeeCalculator = (value, charges) => {
    const charge = charges.find(
        (charge) => value < charge.end && value >= charge.start
    );
    if (charge && charge.type && charge.type === "percentage") {
        // const serviceFee = charge

        const serviceFee = value * (charge.value / 100);
        return serviceFee;
    } else if (charge) {
        const serviceFee = charge.value;
        return serviceFee;
    }
};
const getIntent = () => {

}
export { getCountryState, validatePhoneNumber, getAirtimeNumberType, serviceFeeCalculator, getIntent, normaliseAirtimeNumber, parsePhoneNumber }