const CAN_CARD_PAYMENT = true;
const CAN_EASY_BANK_PAYMENT = true;
const CAN_MANUAL_BANK_PAYMENT = true;
const CAN_WALLET_PAYMENT = true;
const CAN_AFRIMONEY_PAYMENT = false;
const CAN_QMONEY_PAYMENT = false;
const CAN_TRUST_PAYMENT = false;
const CAN_CASH_PAYMENT = false;

export {
    CAN_CARD_PAYMENT,
    CAN_EASY_BANK_PAYMENT,
    CAN_MANUAL_BANK_PAYMENT,
    CAN_WALLET_PAYMENT,
    CAN_AFRIMONEY_PAYMENT,
    CAN_QMONEY_PAYMENT,
    CAN_TRUST_PAYMENT,
    CAN_CASH_PAYMENT,
}