<template>
  <div class="pa-2">
    <GBP-manual-bank-transfer v-if="payment && payment.fromCurrency && payment.fromCurrency === 'GBP'" :payment="payment" />
    <EUR-manual-bank-transfer v-if="payment && payment.fromCurrency && payment.fromCurrency === 'EUR'" :payment="payment" />
    <GMD-manual-bank-transfer v-if="payment && payment.fromCurrency && payment.fromCurrency === 'GMD'" :payment="payment" :intent="intent" />
  </div>
</template>

<script>
import GBPManualBankTransfer from "@tenant/components/payment/manual-bank-transfer-gbp.vue";
import EURManualBankTransfer from "@tenant/components/payment/manual-bank-transfer-eur.vue";
import GMDManualBankTransfer from "@tenant/components/payment/manual-bank-transfer-gmd.vue";
export default {
  props: {
    payment: {
      default: null,
    },
    intent: {
      default: null,
    },
  },
  data: () => ({ drawer: null }),
  components: {
    GBPManualBankTransfer,
    EURManualBankTransfer,
    GMDManualBankTransfer,
  },
  methods: {
    emitNext() {
      this.$emit("next");
    },
  },
  mounted() {
    console.log("this.payment", this.payment);
  }
};
</script>
