<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000px"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'pa-0' : ''">
      <v-card-title>
          <h2 class="subtitle-1">Send Money <span v-if="sendmoneyParams && sendmoneyParams.forUser"> For <strong>{{ sendmoneyParams.forUser.fullname }}</strong></span></h2>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <send-money-stepper :params="{ detail: sendmoneyParams }" :key="sendMoneyConverterKey" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import SendMoneyStepper from "@/components/send-money/send-money-stepper";

const sendmoneyNamespace = createNamespacedHelpers("sendmoney");

export default {
  data() {
    return {};
  },
  computed: {
    ...sendmoneyNamespace.mapState({
      sendmoneyDialog: (state) => state.sendmoneyDialog,
      sendmoneyParams: (state) => state.sendmoneyParams,
      sendMoneyConverterKey: (state) => state.key,
    }),
    dialog: {
      get() {
        return this.sendmoneyDialog;
      },
      set() {
        this.closeDialog();
      },
    },
    isMobile() {
      const mdDown = this.$store.state.breakpoints.mdDown;
      return mdDown.indexOf(this.$mq) > -1;
    },
  },
  components: {
    SendMoneyStepper,
  },
  methods: {
    ...sendmoneyNamespace.mapMutations(["mSendMoney", "mUpdateSendMoneyDialog", "mSetKey", "mResetParams"]),
    closeDialog() {
      this.mUpdateSendMoneyDialog(false);
      this.mResetParams();
      this.mSetKey();
    },
  },
  mounted(){
    // console.log("test send money")
  }
};
</script>
<style scoped>
</style>