<template>
  <div :key="sendMoneyKey">
    <!--  <div class="text--primary text-center pa-5" v-show="confirming">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div> -->
    <div v-if="showSuccess" class="text-center">
      <h3>{{ successParams.title }}</h3>
      <success-alert message="successMessage" />

      <h3 class="text-center pa-3 subtitle-1">
        {{ successParams.message }}
        <span class="">{{
          sendMoneyParams.toAmount | currency(sendMoneyParams.toCurrencyCode)
        }}</span>
        <!-- <span class="">{{ meterNumber }}</span> -->
      </h3>
      <div
        v-if="successParams && successParams.showManualBankTransferMessage"
        class="pa-5"
      >
        <manual-bank-transfer />
      </div>
    </div>
    <v-stepper
      v-model="e6"
      vertical
      elevation="0"
      v-if="!showSuccess && !confirming"
    >
      <v-stepper-step :complete="e6 > 1" step="1" @click="e6 = 1">
        How much would you want to send ?
        <small>Enter the amount you wish to send. </small>
      </v-stepper-step>
      <v-stepper-content step="1" class="pl-3">
        <send-money-converter :show-send-button="false" />
        <v-btn color="primary" class="mr-2" @click="goToAmountStep"
          >Continue</v-btn
        >
        <v-btn text class="red--text" @click="closeDialog(false)">Cancel</v-btn>
      </v-stepper-content>
      <template
        v-if="sendMoneyParams && sendMoneyParams.deliveryType === 'COLLECTION'"
      >
        <v-stepper-step :complete="e6 > 2" step="2" @click="goToAmountStep()">
          <span v-if="sendMoneyParams">
            Recipient Details -
            <strong>{{
              sendMoneyParams.toAmount
                | currency(sendMoneyParams.toCurrencyCode)
            }}</strong>
          </span>
          <span v-else> Recipient Details </span>
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-form
            id=""
            v-model="validRecipient"
            lazy-validation
            ref="recipientForm"
            @submit.prevent="goToPayStep"
            class="px-3 form-weight"
          >
            <v-card class="mt-4" min-height="100px" elevation="0">
              <v-combobox
                v-model="recipient.name"
                :items="recipients"
                :rules="nameRules"
                item-text="name"
                item-value="name"
                :error-messages="errors.collect('recipientName')"
                label="Enter or select recipient name *"
                ref="recipientName"
                outlined
                clearable
                @change="selectRecipient"
              ></v-combobox>
              <!-- <v-text-field
              v-model="recipient.name"
              name="recipientName"
              v-validate="'required'"
              type="text"
              :rules="nameRules"
              :error-messages="errors.collect('recipientName')"
              label="Enter recipient name *"
              ref="recipientName"
              outlined
              clearable
            ></v-text-field> -->
              <!-- prefix="+220" -->
              <v-text-field
                v-model="recipient.mobile"
                name="phoneNumber"
                v-validate="'required'"
                type="text"
                :rules="phoneNumberRules"
                :error-messages="errors.collect('phoneNumber')"
                label="Enter recipient phone number *"
                ref="phoneNumber"
                outlined
                clearable
              ></v-text-field>
              <!-- <v-text-field
              v-model="recipient.mobileNumber"
              name="email"
              v-validate="'required'"
              type="text"
              label="Email"
              ref="email"
              outlined
              clearable
            ></v-text-field> -->

              <v-combobox
                v-model="recipient.relationship"
                :items="relationships"
                label="Relationship"
                ref="relationship"
                outlined
                clearable
              ></v-combobox>
              <v-text-field
                v-model="recipient.city"
                name="city"
                type="text"
                label="City"
                ref="city"
                outlined
                clearable
              ></v-text-field>
            </v-card>
            <v-btn color="primary" type="submit">Continue</v-btn>
            <v-btn text class="red--text" @click="closeDialog(false)"
              >Cancel</v-btn
            >
          </v-form>
        </v-stepper-content>
      </template>
      <template
        v-if="
          sendMoneyParams &&
          (sendMoneyParams.deliveryType === 'BANK' ||
            sendMoneyParams.deliveryType === 'BILL')
        "
      >
        <v-stepper-step :complete="e6 > 2" step="2" @click="goToAmountStep()">
          <span v-if="sendMoneyParams && charge && charge.title">
            {{ charge.title }} Details -
            <strong>{{
              sendMoneyParams.toAmount
                | currency(sendMoneyParams.toCurrencyCode)
            }}</strong>
          </span>
          <span v-else> {{ sendMoneyParams.deliveryType }} Details </span>
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-form
            id=""
            v-model="validRecipient"
            lazy-validation
            ref="recipientForm"
            @submit.prevent="goToPayStep"
            class="px-3 form-weight"
          >
            <v-card class="mt-4" min-height="100px" elevation="0">
              <v-combobox
                v-if="charge && charge.list"
                v-model="bank"
                :items="charge.list"
                item-text="text"
                item-value="value"
                :label="'Select ' + charge.title"
                clearable
                @change="selectDeliveryService"
                outlined
              >
              </v-combobox>

              <v-combobox
                v-model="recipient.name"
                :items="recipients"
                :rules="nameRules"
                item-text="name"
                item-value="name"
                :error-messages="errors.collect('recipientName')"
                label="Enter or select recipient name *"
                ref="recipientName"
                outlined
                clearable
                @change="selectRecipient"
              ></v-combobox>
              <template v-for="item in formElements">
                <v-text-field
                  v-if="item.column !== 'name'"
                  :key="item.key"
                  v-model="recipient[item.column]"
                  :name="item.column"
                  type="text"
                  :label="item.label"
                  :placeholder="item.placeholder"
                  :ref="item.column"
                  outlined
                  clearable
                ></v-text-field>
              </template>
            </v-card>
            <v-btn color="primary" type="submit">Continue</v-btn>
            <v-btn text class="red--text" @click="closeDialog(false)"
              >Cancel</v-btn
            >
          </v-form>
        </v-stepper-content>
      </template>
      <template
        v-if="sendMoneyParams && sendMoneyParams.deliveryType === 'PAY-BILL'"
      >
        <v-stepper-step :complete="e6 > 2" step="2" @click="goToAmountStep()">
          <span v-if="sendMoneyParams">
            Institution Or Service Details -
            <strong>{{
              sendMoneyParams.toAmount
                | currency(sendMoneyParams.toCurrencyCode)
            }}</strong>
          </span>
          <span v-else> Institution Or Service Details </span>
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-form
            id=""
            v-model="validRecipient"
            lazy-validation
            ref="recipientForm"
            @submit.prevent="goToPayStep"
            class="px-3 form-weight"
          >
            <v-card class="mt-4" min-height="100px" elevation="0">
              <v-combobox
                v-model="recipient.name"
                :items="recipients"
                :rules="nameRules"
                item-text="name"
                item-value="name"
                :error-messages="errors.collect('recipientName')"
                label="Enter or select recipient name *"
                ref="recipientName"
                outlined
                clearable
                @change="selectRecipient"
              ></v-combobox>
              <!-- <v-text-field
              v-model="recipient.name"
              name="recipientName"
              v-validate="'required'"
              type="text"
              :rules="nameRules"
              :error-messages="errors.collect('recipientName')"
              label="Enter recipient name *"
              ref="recipientName"
              outlined
              clearable
            ></v-text-field> -->
              <!-- prefix="+220" -->
              <v-text-field
                v-model="recipient.mobile"
                name="phoneNumber"
                v-validate="'required'"
                type="text"
                :rules="phoneNumberRules"
                :error-messages="errors.collect('phoneNumber')"
                label="Enter recipient phone number *"
                ref="phoneNumber"
                outlined
                clearable
              ></v-text-field>
              <!-- <v-text-field
              v-model="recipient.mobileNumber"
              name="email"
              v-validate="'required'"
              type="text"
              label="Email"
              ref="email"
              outlined
              clearable
            ></v-text-field> -->

              <v-combobox
                v-model="recipient.relationship"
                :items="relationships"
                label="Relationship"
                ref="relationship"
                outlined
                clearable
              ></v-combobox>
              <v-text-field
                v-model="recipient.city"
                name="city"
                type="text"
                label="City"
                ref="city"
                outlined
                clearable
              ></v-text-field>
            </v-card>
            <v-btn color="primary" type="submit">Continue</v-btn>
            <v-btn text class="red--text" @click="closeDialog(false)"
              >Cancel</v-btn
            >
          </v-form>
        </v-stepper-content>
      </template>

      <v-stepper-step :complete="e6 > 3" step="3" @click="goToPayStep()"
        >Pay</v-stepper-step
      >
      <v-stepper-content
        step="3"
        style="padding-left: 0px; padding-right: 25px; margin-left: -5px"
      >
        <v-container>
          <v-card
            class="mb-5 pa-0"
            min-height="100px"
            max-width="500"
            elevation="0"
            style="margin: auto"
            :loading="creatingIntent"
          >
            <!-- <div class="text-center">
              <img
                class="text-center"
                height="50"
               
                :src="logo"
              />
            </div> -->

            <!-- <h3 class="text-center pa-3">
              Recipient will get
              <span class="">{{ toAmount | currency(toCurrencyCode) }}</span> on
              <span class="">{{ normalisePhoneNumber.number }}</span>
            </h3> -->
            <!-- <card-payment v-if="e6 === 3"></card-payment> -->
            <card-payment
              v-if="paymentID && paymentParams"
              :key="paymentID"
              @success="paymentSuccessful"
              :can-card-payment="CAN_CARD_PAYMENT"
              :can-easy-bank-payment="CAN_EASY_BANK_PAYMENT"
              :can-instant-bank-payment="CAN_EASY_BANK_PAYMENT"
              :can-manual-bank-payment="CAN_MANUAL_BANK_PAYMENT"
              :can-wallet-payment="CAN_WALLET_PAYMENT"
              :can-trust-payment="CAN_TRUST_PAYMENT"
            />
          </v-card>
        </v-container>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>
<script>
const SendMoneyConverter = () =>
  import("@/components/converter/send-money-converter");
import { createNamespacedHelpers } from "vuex";

import ManualBankTransfer from "@tenant/components/payment/manual-bank-transfer.vue";

import { validatePhoneNumber } from "@/lib/phonenumber";
import {
  MONEYTRANSFER_TOPUP_CODE,
  MONEY_TRANSFER_CONTACT_CODE,
} from "../../const/services";
import { SENDMONEY_RELATIONSHIPS } from "../../const/lists";

import ContactService from "../../services/contact.service";

import {
  CAN_CARD_PAYMENT,
  CAN_EASY_BANK_PAYMENT,
  CAN_MANUAL_BANK_PAYMENT,
  CAN_WALLET_PAYMENT,
  CAN_TRUST_PAYMENT,
} from "@tenant/const/payment.js";

const recipient = {
  name: null,
  bank: null,
  mobile: null,
};

const basketNamespace = createNamespacedHelpers("basket");
const paymentNamespace = createNamespacedHelpers("payment");
//const cashpowerNamespace = createNamespacedHelpers("cashpower");
const sendMoneyNamespace = createNamespacedHelpers("sendmoney");
const usersNamespace = createNamespacedHelpers("users");
import logo from "@tenant/static/images/logos/tenant-logo.png";
export default {
  props: ["params"],
  name: "SendMoneyStepperComponent",
  data() {
    return {
      CAN_CARD_PAYMENT,
      CAN_EASY_BANK_PAYMENT,
      CAN_MANUAL_BANK_PAYMENT,
      CAN_WALLET_PAYMENT,
      CAN_TRUST_PAYMENT,
      logo,
      e6: 1,
      sendMoneyKey: new Date().toString(),
      showSuccess: null,
      successMessage: null,
      toAmount: 1000,
      amount: 16,
      phoneNumber: null,
      recipientName: "",
      serviceFee: "",
      validRecipient: true,
      normalisePhoneNumber: null,
      creatingIntent: false,
      confirming: false,
      deliveryNote: null,
      formElements: [],
      deliveryService: {},
      successParams: {
        title: "Payment successful",
        message: "Recipient will get",
        showManualBankTransferMessage: false,
      },
      bank: null,
      charge: {},
      recipient: { ...recipient },
      recipients: [],
      relationships: SENDMONEY_RELATIONSHIPS,
      phoneNumberRules: [
        (v) => !!v || "Recipient Phone Number is required",
        (v) =>
          (v && validatePhoneNumber(v) !== null) || "Phone number is not valid",
        (v) => {
          if (!(v && v.length >= 7)) {
            return "Invalid phone number";
          }
          /*  const n = validatePhoneNumber(v);
          if (!n.valid) {
            return "Invalid phone number";
          } */
          return true;
        },
      ],
      nameRules: [
        (v) => !!v || "Recipient name is required",
        (v) => {
          if (!(v && v.length != "")) {
            return "Invalid Name";
          }
          return true;
        },
        (v) => {
          if (v && v.trim().split(" ").length < 2) {
            return "Last name is required";
          }
          return true;
        },
      ],
      amountRules: [
        (v) => !!v || "Amount is required",
        (v) => {
          if (!(v && v > 0)) {
            return "Invalid amount";
          }
          return true;
        },
      ],
    };
  },
  components: {
    CardPayment: () => import("@/components/payment/card-payment"),
    SuccessAlert: () => import("@/components/alert/success"),
    SendMoneyConverter,
    ManualBankTransfer,
  },
  computed: {
    ...paymentNamespace.mapState({
      paymentID: (state) => state.paymentID,
      paymentParams: (state) => state.paymentParams,
      paymentIntent: (state) => state.paymentIntent,
    }),
    ...usersNamespace.mapState({
      serverConfig: (state) => state.serverConfig,
      wallet: (state) => state.wallet,
      user: (state) => state.user,
      isAuthenticated: (state) => state.isAuthenticated,
    }),
    ...sendMoneyNamespace.mapState({
      sendMoneyParams: (state) => state.params,
      deliveryTypes: (state) => state.deliveryTypes,
    }),
    isMobile() {
      const mdDown = this.$store.state.breakpoints.mdDown;
      return mdDown.indexOf(this.$mq) > -1;
    },
    layout() {
      return this.$store.state.layout;
    },
    fromCurrencyCode: {
      get() {
        return this.sFromCurrencyCode;
      },
      set(value) {
        this.mSetFromCurrencyCode({
          fromCurrencyCode: value,
          serviceCode: "CASHPOWER",
        });
      },
    },
    dialog: {
      get() {
        return this.sendmoneyDialog;
      },
      set() {
        this.closeDialog();
      },
    },
  },
  methods: {
    ...paymentNamespace.mapActions(["aCreatePaymentIntent"]),
    ...paymentNamespace.mapMutations(["mResetPayment", "mStartPayment"]),
    ...basketNamespace.mapActions(["aSetDetails"]),
    ...basketNamespace.mapMutations(["mSetFromCurrencyCode"]),
    ...sendMoneyNamespace.mapActions(["aCreatePaymentIntent"]),
    ...sendMoneyNamespace.mapMutations(["mUpdateSendMoneyDialog"]),
    ...usersNamespace.mapMutations(["mSetLoginDialog", "mSetSignupDialog"]),
    selectDeliveryService(deliveryService) {
      this.resetRecipient();
      if (deliveryService) {
        this.deliveryService = deliveryService;
        this.recipient.bank = deliveryService.value;
        console.log("this.deliveryService", this.deliveryService);
        this.setRecipients();
        this.setFormElements(deliveryService);
      }
    },
    setFormElements(deliveryService) {
      const formElements = [...deliveryService.formElements];
      const recipient = this.recipient;

      formElements.forEach((item) => {
        const key = deliveryService.code + "#" + item.column;
        item.key = key;
        recipient[item.column] = null;
      });

      this.formElements = formElements;
    },
    async loadContacts() {
      try {
        const response = await ContactService.getContacts(
          MONEY_TRANSFER_CONTACT_CODE
          //this.wallet.publicCode
          // this.walletCode
        );
        this.allRecipients = response.data;
        this.setRecipients();
        console.log("send money contacts", this.allRecipients);
      } catch (error) {
        //
      }
    },
    setRecipients() {
      const allRecipients = this.allRecipients;
      const deliveryType = this.sendMoneyParams.deliveryType;
      if (deliveryType === "COLLECTION") {
        this.recipients = allRecipients.filter(
          (item) => !item.bank || item.deliveryType === "COLLECTION"
        );
      } else {
        const bank = this.recipient.bank;
        const recipients = allRecipients.filter(
          (item) => item.deliveryType === deliveryType && item.bank === bank
        );
        this.recipients = recipients;
        //
      }
    },
    selectRecipient(data) {
      try {
        // const mobile =
        if (typeof data === "object" && !Array.isArray(data) && data !== null) {
          this.recipient = { ...data };
        }
      } catch (error) {
        //
      }
      console.log("data", data);
      console.log("this.recipient", this.recipient);
    },
    closeDialog() {
      this.mUpdateSendMoneyDialog(false);
    },
    paymentSuccessful(params) {
      try {
        const p = { ...this.successParams, ...params };
        this.successParams = p;
      } catch (err) {
        //
      }

      this.showSuccessfulMessage();
    },
    showSuccessfulMessage() {
      this.showSuccess = true;
    },
    paymentError() {
      //
    },
    setStartStep(step) {
      if (!this.isAuthenticated) {
        return this.openLoginDialog();
      }
      if (step == 2) {
        this.goToAmountStep();
      } else if (step) {
        this.e6 = step;
      } else if (this.sendMoneyParams && this.sendMoneyParams.startStep) {
        if (this.sendMoneyParams.startStep == 2) {
          this.goToAmountStep();
        } else {
          this.e6 = this.sendMoneyParams.startStep;
        }
      }
    },
    /*    async createIntent() {
      const charges = this.serverConfig
        ? this.serverConfig.service.nawecRates
        : null;
      if (charges) {
        const calcParams = {
          serviceCode: this.serviceCode,
          fromAmount: this.amount,
          fromCurrencyCode: this.fromCurrencyCode,
          toCurrencyCode: this.toCurrencyCode,
        };
        const toAmount = Math.round(calculateAmount(calcParams));
        this.toAmount = toAmount;
        this.serviceFee = serviceFeeCalculator(toAmount, charges);
        const intentRequest = {
          meterNumber: this.meterNumber,
          serviceFee: this.serviceFee,
          mobileNumber: this.normalisePhoneNumber.number,
          amount: toAmount,
        };

        try {
          this.creatingIntent = true;
          await this.aCreateNawecPaymentIntent(intentRequest);
          this.$log.debug("intents", this.intents);
        } catch (error) {
          this.$store.commit("mShowAlert", error);
        }
        this.creatingIntent = false;
      } else {
        this.$store.commit("mShowAlert", "ServerConfig not availiable");
      }
    }, */
    async confirm(message) {
      // this.$log.debug('paymentMessage', message);
      const payload = {
        walletCode: message.walletCode,
        secret: this.transactionDetail.secret,
      };
      this.confirming = true;
      try {
        await this.aConfirmNawecPaymentIntent(payload);
        this.confirming = false;
        this.showSuccessfulMessage();
      } catch (error) {
        //
      }
      this.confirming = false;
    },
    async pay() {
      // @todo : create payment intent
      try {
        const sendMoneyParams = this.sendMoneyParams;
        const recipient = this.recipient;
        recipient.deliveryType = sendMoneyParams.deliveryType;

        const wallet = this.wallet;
        const user = this.user;

        // let userCode = user.code;

        console.log("intent.recipient", recipient);

        const intent = {};
        intent.item = sendMoneyParams;
        intent.recipient = recipient;
        intent.sender = {
          userCode: user.code,
          name: user.fullname,
          mobileNumber: user.mobile,
          walletCode: wallet.code,
          bank: null,
          bankAccountNumber1: null,
          bankAccountNumber2: null,
          address1: null,
          address2: null,
          address3: null,
          address4: null,
          zipPostalCode: null,
          country: null,
          email: user.email ? user.email : user.username,
        };

        if (sendMoneyParams && sendMoneyParams.forUser) {
          const forUser = sendMoneyParams.forUser;
          intent.sender.userCode = forUser.code;
          intent.sender.name = forUser.fullname;
          intent.sender.mobileNumber = forUser.mobile;
          intent.sender.email = forUser.email;
        }

        this.creatingIntent = true;
        await this.aCreatePaymentIntent(intent);
        this.creatingIntent = false;

        const params = {
          amount: sendMoneyParams.fromAmount,
          toAmount: sendMoneyParams.toAmount,
          type: MONEYTRANSFER_TOPUP_CODE,
          fromCurrency: sendMoneyParams.fromCurrencyCode,
          toCurrency: sendMoneyParams.toCurrencyCode,
          fromCurrencyCode: sendMoneyParams.fromCurrencyCode,
          toCurrencyCode: sendMoneyParams.toCurrencyCode,
          displayType: "INLINE",
        };

        this.mStartPayment({ params });
      } catch (error) {
        this.creatingIntent = false;
        this.e6 = 2;
        //this.$store.commit("mShowAlert", error);
      }
      //this.creatingIntent = false;
      // EADD93D8-F08A-445B-A25D-514F038721C7-16451071086579g5i0
    },
    openSignupDialog() {
      this.mSetSignupDialog(true);
    },
    openLoginDialog() {
      this.mSetLoginDialog(true);
    },
    goToPayStep() {
      console.log("this.recipient", this.recipient);
      if (!this.isAuthenticated) {
        return this.openLoginDialog();
      }
      // this.pay();
      // const sendMoneyParams = this.sendMoneyParams;
      // const recipient = this.recipient;
      // console.log('sendMoneyParams', sendMoneyParams);
      // console.log('recipient', recipient);
      this.validRecipient = this.$refs.recipientForm.validate();
      if (this.validRecipient) {
        this.e6 = 3;
        this.pay();
      } else {
        this.$store.commit("mShowAlert", "Fill missing fields ");
      }
    },
    goToAmountStep() {
      // alert("goToAmountStep");
      // console.log("amount step 1");
      console.log("charge.list", this.charge.list);
      if (!this.isAuthenticated) {
        return this.openLoginDialog();
      }

      const recipient = this.recipient;

      if (
        recipient &&
        recipient.deliveryType !== this.sendMoneyParams.deliveryType
      ) {
        this.resetRecipient();
      }

      console.log("this amount", this.sendMoneyParams.fromAmount);

      //this.validAmount = this.$refs.amountForm.validate();

      if (this.e6 && this.sendMoneyParams.fromAmount > 0) {
        console.log("amount step 2");
        this.e6 = 2;
        this.setUpDelivery();
      } else {
        this.$store.commit("mShowAlert", "Amount is required");
      }

      this.loadContacts();
    },
    setUpDelivery() {
      console.log("this.sendMoneyParams", this.sendMoneyParams);
      console.log("this.deliveryTypes", this.deliveryTypes);

      const sendMoneyParams = this.sendMoneyParams;
      const charge = this.deliveryTypes.find(
        (item) => item.code === sendMoneyParams.deliveryType
      );
      console.log("charge", charge);

      this.charge = charge;
    },
    goToStepper(number) {
      if (this.e6 > number) {
        this.e6 = number;
      }
    },

    /* ...basketNamespace.mapActions(["aDetail"]), */

    // ...usersNamespace.mapActions(["aSaveServerConfig"]),
    focus(ref) {
      try {
        this.$refs[ref].$refs.input.focus();
      } catch (error) {
        // error
        this.$store.commit("mShowAlert", error);
      }
    },
    reInitiate() {
      this.sendMoneyKey = new Date().toString();
      this.initiate(1);
    },
    resetRecipient() {
      const r = { ...recipient };
      r.deliveryType = this.sendMoneyParams.deliveryType;
      this.recipient = r;
    },
    initiate(step) {
      this.successMessage = null;
      this.showSuccess = false;
      this.setStartStep(step);
      this.operator = null;
      this.toAmount = 500;
      this.amount = 16;
      this.phoneNumber = null;
      this.normalisePhoneNumber = null;
      this.creatingIntent = false;

      this.resetRecipient();
      this.deliveryNote = null;
      //this.mSetBundles(this.serviceCode);

      //this.meterNumber = "";
      // this.phoneNumber = "";

      // this.$refs.meterNumber.focus();
      // console.log("this.params", this.params);
      /* const detail = this.params ? { ...this.params.detail } : null;
      if (detail) {
        
        this.meterNumber = detail.meterNo ? detail.meterNo : "";
        this.phoneNumber = detail.mobile ? detail.mobile : "";
      } */
    },
  },
  beforeMount() {
    this.mResetPayment();
  },
  mounted() {
    this.initiate();
    this.loadContacts();
    // console.log("this.sendMoneyParams", this.sendMoneyParams);
    console.log("this.serverConfig", this.serverConfig);
  },
};
</script>
<style>
</style>
