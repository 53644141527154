const SENDMONEY_RELATIONSHIPS = [
    "Parent",
    "Grandparent",
    "Uncle",
    "Aunt",
    "Partner",
    "Friend",
    "Colleague",
    "Other",
  ];

  export {
    SENDMONEY_RELATIONSHIPS,
  }