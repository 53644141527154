<template>
  <div class="pa-2">
    <h1 class="subtitle-2 pb-5">
      Please make a bank transfer to any of these bank accounts with
      <strong>{{ payment && payment.payInCode ?  payment.payInCode : 'your full name' }} </strong> as reference or comment. Click confirm after making the transfer.
    </h1>
    
     <v-list two-line v-if="bankAccounts && bankAccounts.length">
        <template v-for="(item, i) in bankAccounts">
          <v-list-item :key="'list' + i">
            <v-list-item-avatar v-if="item.image" class="rounded">
            <img :src="item.image" style="width: 100px;" />
          </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.bankName }}</v-list-item-title>
              <v-list-item-subtitle><strong>Account Name&nbsp;&nbsp;&nbsp;&nbsp;</strong> <br />{{ item.name }} <br /> <strong>Account Number</strong> <br />{{ item.accountNumber }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="i" />
        </template>
      </v-list>

    <div>
      <v-alert outlined type="warning" prominent border="left">
        Please note failure to transfer the full amount within 24 hours will
        result in delays processing your transaction or cancellation
      </v-alert>
    </div>
  </div>
</template>

<script>
import { bankAccounts } from "@/const/deposit-bank-accounts.js";

export default {
  props: {
    payment: {
      default: null,
    },
    intent: {
      default: null,
    },
  },
  computed: {

  },
  data: () => ({ 
    drawer: null,
    bankAccounts,
  }),
  methods: {
    emitNext() {
      this.$emit("next");
    },
  },
  mounted() {
    // console.log("payment", this.payment);
    // console.log("intent", this.intent);
    // console.log("depositBankAccounts", this.depositBankAccounts);
  }
};
</script>
